/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Text, Subtitle, ContactForm } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="pb--50 pt--40" name={"einleitung"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" style={{"maxWidth":1030}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":900}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/148/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/148/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/148/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/148/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/148/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/148/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box" content={"Peter Nemec"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":602}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Subtitle className="subtitle-box lh--14 mt--16" content={"+49 797 811 2X05<br>info@vase-stranky.com"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":500}} content={"Firmen-Ident.-Nr.: 12345678<br>Ust.-Ident. Nr. 12345678"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-c5sjl8 pb--80 pt--80" name={"kontakt"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Kontaktieren Sie uns</span>"}>
              </Title>

              <ContactForm className="--shape2 --style3 --right mt--40" style={{"maxWidth":800}} action={"/contact"} fields={[{"name":"Ganze Name","type":"text","required":true,"placeholder":"Bitte tragen Sie Ihren Namen ein"},{"name":"E-Mail Kontakt:","type":"email","required":true,"placeholder":"Bitte geben Sie Ihre e-Mail Adresse ein"},{"name":"Nachricht","type":"textarea","required":true,"placeholder":"Bitte geben Sie Text Ihrer Nachricht ein"},{"name":"Versenden","type":"submit","align":"center"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Peter Nemec</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Über uns</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"+49 797 811 2X05<br>info@vase-stranky.com"}>
              </Text>

              <Text className="text-box" content={"Firmen-Ident.-Nr.: 12345678<br>Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}